import * as R from 'ramda';
import { randomBetween } from ".";

export const randomColorIntensity = () => randomBetween({ min: 0, max: 255 });

export const randomRGB = (separator: string = ","): string =>
  R.times(randomColorIntensity, 3).join(separator);

export const determineAlphaValue = (alpha: number) =>
  alpha >= 0.0 && alpha <= 1.0 ? alpha : 1;

export const randomColorWithAlpha = (alpha: number) =>
  R.compose(
    (rgb: string): string => `rgba(${rgb},${determineAlphaValue(alpha)})`,
    randomRGB
  )();

export const transparent = R.always("rgba(0,0,0,0.0)");
