import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePdf } from "../../pdf/pdf";
import Complaints from "../Complaints/Complaints";
import { IShowInfoContent } from "../Info/Info";
import Logo from "../Logo/Logo";
import Radar from "../Radar/Radar";
import { IFormData } from "../Survey/Survey";
import TextButton from "../TextButton/TextButton";
import Verdict from "../Verdict/Verdict";
import "./Results.css";
import { sortTuples } from "./utils";

interface ResultsProps extends IShowInfoContent {
  rawData: IFormData;
  data: any[][];
  next?: any;
  score: number;
  sensitivity: string;
  setScrollable?: any;
}

function Results(props: ResultsProps) {
  const { t } = useTranslation();
  const {
    rawData,
    data,
    next,
    score,
    sensitivity,
    setScrollable: _setScrollable,
    showInfo,
  } = props;
  const [sortedComplaints, setSortedComplaints] = useState<any[][]>([]);
  const [expanded, setExpanded] = useState(false);
  const [pdfCounter, setPdfCounter] = useState(0);
  const canvas = useRef<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setScrollable = useCallback(_setScrollable, []);

  useEffect(() => {
    setScrollable(expanded);
  }, [expanded, setScrollable]);

  useEffect(() => {
    setSortedComplaints(sortTuples(data));
  }, [data]);

  const handleClick = () => {
    if (canvas.current.canvas) {
      const dataURL = canvas.current.canvas.toDataURL("image/png");
      generatePdf({
        ...rawData,
        scoreLabel: `${score} ${t("results.verdict.degrees." + sensitivity)}`,
        sorted: sortedComplaints,
        sensitivityLabel: sensitivity,
        chart: dataURL,
      });
      setPdfCounter(pdfCounter + 1);
    }
  };

  return (
    <div className="Results">
      <div className="wrapper">
        <h3 className="title">
          <Trans
            i18nKey="results.title"
            t={t}
            components={[
              <Logo textMode inlineMode size="24px" color="#0070CE" />,
            ]}
          />
        </h3>
        <Verdict score={score} sensitivity={sensitivity} showInfo={showInfo} />
        <TextButton
          className="download"
          style={{ backgroundColor: "#13A9AF", fontSize: "20px" }}
          onClick={handleClick}
          label={t(
            pdfCounter > 0
              ? "results.downloadAgainAction"
              : "results.downloadAction"
          )}
        />
        <p className="top3text">
          <Trans
            i18nKey="results.top3Major"
            t={t}
            components={[t("results.verdict.degrees." + sensitivity)]}
          />
        </p>
        <Complaints
          complaints={sortedComplaints}
          toggleExpanded={() => setExpanded(!expanded)}
          expanded={expanded}
        >
          {sortedComplaints.length && (
            <Radar
              expanded={expanded}
              complaints={sortedComplaints}
              canvasRef={canvas}
            />
          )}
        </Complaints>
        <TextButton
          className="to-products"
          onClick={next}
          label={t("results.howHelpAction")}
        />
      </div>
    </div>
  );
}

export default Results;
