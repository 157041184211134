import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { deRunt, noBreakSpace, withClass } from "../../../helpers";
import Logo from "../../Logo/Logo";
import Slide from "../Slide/Slide";
import "./NearlyThere.css";

interface NearlyThereProps {
  className?: string;
  setValid: (_: boolean) => void;
}

function NearlyThere({ className, setValid }: NearlyThereProps) {
  const { t } = useTranslation();
  useEffect(() => {
    setValid(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slide className={`NearlyThere${withClass(className)}`}>
      <p>{deRunt(t("nearlyThere.header"))}</p>
      <p>{deRunt(t("nearlyThere.paragraph1"))}</p>
      <p>
        {deRunt(t("nearlyThere.paragraph2"))}
        {noBreakSpace}
        <Logo textMode inlineMode size="18px" />
      </p>
    </Slide>
  );
}

export default NearlyThere;
