import { join, replace } from "ramda";

/**
 * No-break-space character in Unicode
 */
export const noBreakSpace = "\u00a0";

/**
 * Joins elements with No-break-space
 */
export const joinWithNoBreakSpace = join(noBreakSpace);

/**
 * Eliminates typography runts by adding No-break-space
 * before last word in string
 */
export const deRunt = replace(
  /([^\s])\s+([^\s]+)\s*$/,
  (_, g1, g2) => `${g1}${noBreakSpace}${g2}`
);
