import iconBackHovered from "../../assets/back-hover.svg";
import iconBack from "../../assets/back.svg";
import iconInfoHovered from "../../assets/info-hover.svg";
import iconInfo from "../../assets/info.svg";
import iconNextHovered from "../../assets/next-hover.svg";
import iconNext from "../../assets/next.svg";
import { IButton, IButtonBase, ISVGIcon } from "../Button/Button";

interface ButtonProps extends IButtonBase {
  icon: ISVGIcon;
  className: string;
}

const getActionButton = ({
  className,
  icon,
  ...rest
}: ButtonProps): IButton => ({
  className,
  size: "60px",
  icon,
  ...rest,
});

export const getActionPrevButton = (props: IButtonBase): IButton =>
  getActionButton({
    icon: { regular: iconBack, hover: iconBackHovered },
    className: "prev",
    ...props,
  });

export const getActionNextButton = (props: IButtonBase): IButton =>
  getActionButton({
    icon: { regular: iconNext, hover: iconNextHovered },
    className: "next",
    ...props,
  });

export const getActionInfoButton = ({
  title,
  onClick,
}: IButtonBase): IButton => ({
  className: "info",
  size: "38px",
  icon: { regular: iconInfo, hover: iconInfoHovered },
  title,
  onClick,
});
