import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import iconClose from "../../assets/close.svg";
import { renderWhen } from "../../helpers";
import Button from "../Button/Button";
import Logo, { LogoSize } from "../Logo/Logo";
import "./Info.css";

/**
 * Content of Info OVerlay
 */
export type InfoContent = {
  /* should display logo in header */
  withHeaderLogo?: boolean | number;
  title: string;
  paragraph: string;
};
interface IInfoProps {
  content: InfoContent;
  dismiss: () => void;
}
export interface IShowInfoContent {
  showInfo: Dispatch<InfoContent | null>;
}

function Info(props: IInfoProps) {
  const { t } = useTranslation();
  const {
    content: { paragraph, title, withHeaderLogo = false },
    dismiss,
  } = props;
  const logoSize = withHeaderLogo ? `${withHeaderLogo}px` : LogoSize.Medium;

  return (
    <div className="Info">
      <div className="Info-content">
        {renderWhen(
          <Logo size={logoSize} color="white" textMode />,
          Boolean(withHeaderLogo)
        )}
        <h4 className="title">{title}</h4>
        <p className="paragraph">{paragraph}</p>
      </div>
        <Button
          size="60px"
          className="close"
          onClick={dismiss}
          icon={{ regular: iconClose }}
          title={t("actions.close")}
        />
    </div>
  );
}

export default Info;
