import ProgressBar from "react-bootstrap/ProgressBar";
import { renderWhen } from "../../helpers";
import "./Progress.css";

interface ProgressProps {
  status?: number;
}

function Progress(props: ProgressProps) {
  const { status = -1 } = props;

  return renderWhen(
    <div className="Progress">
      <span className="label">{`${status}%`}</span>
      <div className="bar">
        <ProgressBar variant="info" now={status} isChild />
      </div>
    </div>,
    status >= 0 && status < 100
  );
}

export default Progress;
