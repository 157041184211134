import { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import logo from "../../assets/sensiscale_grid_v2_LR.gif";
import Logo from "../Logo/Logo";
import "./Processing.css";

interface ProcessingProps {
  next: () => void;
}

// processing timeout in milliseconds
const PROCESSING_TIMEOUT_MS = 5000; // 5s

function Processing({ next: _next }: ProcessingProps) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const next = useCallback(_next, []);

  useEffect(() => {
    const timeout = setTimeout(next, PROCESSING_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [next]);

  return (
    <div className="Processing">
      <div className="chart">
        <img src={logo} alt="Processing" />
      </div>
      <p>
        <Trans
          i18nKey="processing.description"
          t={t}
          components={[
            <Logo textMode inlineMode size="23px" color="#004987" />,
          ]}
        />
      </p>
    </div>
  );
}

export default Processing;
