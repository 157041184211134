import "chart.js/auto";
import { TFunction } from "i18next";
import { times } from "ramda";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import logo from "../../assets/sensiscale_grid_v2_LR.gif"
import iconStart from "../../assets/get-started-3@3x.png";
import iconStartHover from "../../assets/get-started-hover-3@3x.png";
import iconInfoHover from "../../assets/info-hover.svg";
import iconInfo from "../../assets/info.svg";
import Button from "../Button/Button";
import { IShowInfoContent } from "../Info/Info";
import Logo from "../Logo/Logo";
import {
  buildTranslationKeys,
  emptyData,
  randomColorWithAlpha,
  randomData,
  transparent,
  whenDevMode,
  whenProdMode,
} from "../../helpers";
import "./Welcomer.css";

const NUMBER_OF_GRIDS = 10;
const ANIMATION_INTERVAL_MS = 2500;

/**
 * Consider removal if no longer needed
 * Generates number of datasets for chart.js radar component
 * @param tFn i18n translate function
 * @param amount number of data sets to generate
 * @returns chart.js datasets
 */
const generateData = (tFn: TFunction, amount: number) => ({
  labels: buildTranslationKeys(NUMBER_OF_GRIDS).map(tFn),
  datasets: [
    ...times(
      () => ({
        label: "",
        backgroundColor: randomColorWithAlpha(0.6),
        data: randomData(NUMBER_OF_GRIDS),
      }),
      amount
    ),
    {
      label: "",
      borderColor: transparent(),
      backgroundColor: transparent(),
      data: emptyData(NUMBER_OF_GRIDS),
    },
  ],
});

interface WelcomerProps extends IShowInfoContent {
  next?: any;
}

function Welcomer(props: WelcomerProps) {
  const { t } = useTranslation();
  const { next, showInfo } = props;
  const [data, setData] = useState(generateData(t, 2));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setData(generateData(t, 2));
    }, ANIMATION_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [t]);

  const info = () =>
    showInfo({
      paragraph: t("welcome.info_content"),
      title: t("welcome.info_title"),
      withHeaderLogo: 24,
    });

  const chartOptions = {
    scales: {
      r: {
        pointLabels: { display: false },
        ticks: { display: false },
      },
    },
    plugins: { legend: { display: false } },
  };

  return (
    <div className="Welcomer">
      <div className="chart">
        {whenDevMode(<Chart type="radar" data={data} options={chartOptions} />)}
        {whenProdMode(<img src={logo} alt={t("welcome.subtitle")} />)}
      </div>
      <Logo subtitle={t("welcome.subtitle")} />

      <Button
        size="40px"
        spacing="32px"
        className="InfoButton"
        onClick={info}
        icon={{ regular: iconInfo, hover: iconInfoHover }}
        title={t("welcome.info")}
      />
      <Button
        size="41px"
        className="StartButton"
        onClick={next}
        icon={{ regular: iconStart, hover: iconStartHover }}
        title={t("welcome.link_start")}
      />
    </div>
  );
}

export default Welcomer;
