import * as R from 'ramda';
import { rangeTo } from '.';

export const buildIndexedTranslationKey = (i: number): string =>
  `welcome.radar_label${i}`;

export const buildIndexedTranslationKeys = (indexes: number[]): Array<string> =>
  R.map(buildIndexedTranslationKey, indexes);

export const buildTranslationKeys = R.compose(
  buildIndexedTranslationKeys,
  rangeTo
);
