import { always, cond, equals, T, values } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isPresent, withClassWhen } from "../../helpers";
import { Sex } from "../Forms/BasicInfo/BasicInfo";
import "./SexAccordion.css";

const SexAccordion = (props: { defaultValue: any; onChange: any }) => {
  const { t } = useTranslation();
  const placeholder = always(t("basicInfo.sex.placeholder"));
  const [label, setLabel] = useState(placeholder);
  const [value, setValue] = useState(props.defaultValue);
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = (e: any) => {
    const { dataset, innerText } = e.target;
    setValue(dataset.value);
    setLabel(innerText);
    setCollapsed(isPresent(dataset.value));
  };

  const determineLabelBySex = cond([
    [equals(Sex.male), always(t("basicInfo.sexMale"))],
    [equals(Sex.female), always(t("basicInfo.sexFemale"))],
    [equals(Sex.other), always(t("basicInfo.sexOther"))],
    [T, placeholder],
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const onChange = useCallback(props.onChange, [props.onChange]);

  useEffect(() => {
    props.onChange(value);
    setLabel(determineLabelBySex(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={`SexAccordion card input-area${withClassWhen(
        "open",
        !collapsed
      )}${withClassWhen("with-content", Boolean(value && value !== -1))}`}
    >
      <div className={`card-header`}>
        <button
          className="btn btn-link input-text"
          onClick={() => setCollapsed(!collapsed)}
        >
          {label}
        </button>
        <i className="glyphicon glyphicon-menu-down blue"></i>
      </div>

      <div className={`collapse${withClassWhen("collapsed", collapsed)}`}>
        <div className="card-body">
          <ul className="list-group">
            {values(Sex).map((s) => (
              <li
                key={s}
                className="list-group-item input-text"
                data-value={s}
                onClick={handleClick}
              >
                {determineLabelBySex(s)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SexAccordion;
