import { all, complement, defaultTo, equals, isNil } from "ramda";

export const isTruthy = Boolean;
export const isFalsy = complement(isTruthy);

export const isTrue = equals(true);
export const allAreTrue = all(isTrue);

export const isPresent = complement(isNil);

export const defaultToEmptyObj = defaultTo({});
export const defaultToEmptyArray = defaultTo([]);
export const defaultToZero = defaultTo(0);
export const defaultToNegative = defaultTo(-1);
export const defaultToEmptyString = defaultTo("");
