import * as R from "ramda";
import { randomBetween, shuffle } from ".";

const min3Max9 = R.always({ min: 3, max: 9 });

export const randomData = (amount: number) =>
  R.compose(shuffle, R.map(randomBetween), R.times(min3Max9))(amount);

export const emptyData = (amount: number) => [
  ...R.compose(R.times((_) => 0))(amount - 1),
  10,
];
