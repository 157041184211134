import { withClassWhen } from "../../../helpers";
import { equals10, isInitialOrEq0 } from "../VerticalSlider/VerticalSlider";
import "./RangeScale.css";

interface RangeScaleProps {
  currentValue: number;
}
function RangeScale(props: RangeScaleProps) {
  const { currentValue } = props;

  return (
    <datalist className="RangeScale">
      <option
        className={`pull-left${withClassWhen(
          "hidden",
          isInitialOrEq0(currentValue)
        )}`}
        label="0"
      />
      <option
        className={`pull-right${withClassWhen(
          "hidden",
          equals10(currentValue)
        )}`}
        label="10"
      />
    </datalist>
  );
}

export default RangeScale;
