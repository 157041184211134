import { Trans, useTranslation } from "react-i18next";
import iconInfoHover from "../../assets/info-hover.svg";
import iconInfo from "../../assets/info.svg";
import Button from "../Button/Button";
import { IShowInfoContent } from "../Info/Info";
import WhiteBox from "../WhiteBox/WhiteBox";
import "./Verdict.css";

interface VerdictProps extends IShowInfoContent {
  score: number;
  sensitivity: string;
}

function Verdict(props: VerdictProps) {
  const { t } = useTranslation();
  const { showInfo: _showInfo, score, sensitivity } = props;

  const showInfo = () =>
    _showInfo({
      paragraph: t("results.verdict.info.content"),
      title: t("results.verdict.info.title"),
      withHeaderLogo: 24,
    });

  return (
    <WhiteBox className="Verdict">
      <div className="rating">{score || 0}</div>
      <div className="description">
        <Trans
          i18nKey="results.verdict.sentiviteSkin"
          t={t}
          components={[t("results.verdict.degrees." + sensitivity)]}
        />
      </div>
      <div className="info">
        <Button
          icon={{ regular: iconInfo, hover: iconInfoHover }}
          onClick={showInfo}
          size="24px"
          title={t("actions.info")}
        />
      </div>
    </WhiteBox>
  );
}

export default Verdict;
