import { deRunt } from "../../../helpers";
import "./Legend.css";

interface LegendProps {
  content: string;
}

function Legend({ content }: LegendProps) {
  return <p className="Legend">{deRunt(content)}</p>;
}

export default Legend;
