import { evolve } from "ramda";
import {
  deRunt,
  isTruthy,
  renderWhen,
  whenDevMode,
  withClassWhen,
} from "../../../helpers";
import Info, { InfoContent, IShowInfoContent } from "../../Info/Info";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Layout.css";

interface LayoutProps extends IShowInfoContent {
  children: any;
  info: InfoContent | null;
  scrollable: boolean;
  bgColor: string;
  started: boolean;
}

function Layout(props: LayoutProps) {
  const { children, info, showInfo, started, scrollable, bgColor: backgroundColor } = props;
  const dismiss = () => showInfo(null);

  const withBlurredClass = withClassWhen("blurred", isTruthy(info));
  const withStartedClass = withClassWhen("started", started);
  const withScrollableClass = withClassWhen("scrollable", scrollable);
  const deRuntInfo = evolve({ title: deRunt, paragraph: deRunt });

  return (
    <>
      <div
        className={`Content${withBlurredClass}${withStartedClass}${withScrollableClass}`}
        style={{backgroundColor}}
      >
        {renderWhen(<Header />, started)}
        <div className="Content-elements">{children}</div>
      </div>
      {info && <Info dismiss={dismiss} content={deRuntInfo(info)} />}
      {whenDevMode(<Footer />)}
    </>
  );
}

export default Layout;
