import { always, cond, equals, join, T } from "ramda";
import { useTranslation } from "react-i18next";
import { withClassWhen } from "../../helpers";
import "./Complaint.css";

interface ComplaintProps {
  index: number;
  name: string;
  expanded: boolean;
  className?: string;
}
export const top1color = "#C8284F"; // red
export const top2color = "#F9441A"; // orange
export const top3color = "#76C55C"; // green

export const determineColorById = cond([
  [equals(0), always(top1color)],
  [equals(1), always(top2color)],
  [equals(2), always(top3color)],
  [T, always("none")],
]);

function Complaint(props: ComplaintProps) {
  const { t } = useTranslation();
  const { index, name, expanded } = props;

  const style = {
    backgroundColor: expanded ? "none" : determineColorById(index),
    display: !expanded && index >= 3 ? "none" : "flex",
  };
  const label = join(". ", [index + 1, t(`results.complaints.${name}`)]);
  const withFullClass = withClassWhen("full", expanded);
  const withTop3Class = withClassWhen("top3", index < 3);

  return (
    <div className={`Complaint${withFullClass}${withTop3Class}`} style={style}>
      {label}
    </div>
  );
}

export default Complaint;
