import { defaultTo } from "ramda";
import React, { Dispatch } from "react";
import { withClass } from "../../helpers";
import { withHover, WithHoverProps } from "../withHover";
import "./TextButton.css";

export interface ITextButton {
  label: string;
  onClick?: Dispatch<any>;
  url?: string;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  isLink?: boolean;
}

export interface TextButtonProps extends ITextButton, WithHoverProps {}

function TextButton(props: TextButtonProps) {
  const {
    className,
    hoverRef,
    label,
    onClick,
    style,
    title,
    isLink = false,
    url,
  } = props;

  return isLink && url ? (
    <a
      href={url}
      className={`TextButton link${withClass(className)}`}
      style={style}
    >
      {defaultTo(label, title)}
    </a>
  ) : (
    <input
      type="button"
      className={`TextButton${withClass(className)}`}
      title={defaultTo(label, title)}
      value={label}
      ref={hoverRef}
      onClick={onClick}
      style={style}
    />
  );
}

export default withHover(TextButton);
