import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";

const getLanguages = (
  tFn: TFunction
): { [key: string]: { nativeName: string } } => ({
  en: { nativeName: tFn("lngSwitcher.en") },
  pl: { nativeName: tFn("lngSwitcher.pl") },
});

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const lngs = getLanguages(t);
  return (
    <div className="LanguageSwitcher">
      {Object.keys(lngs).map((lng) => (
        <button
          key={lng}
          style={{
            fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
          }}
          type="submit"
          onClick={() => {
            i18n.changeLanguage(lng);
          }}
        >
          {lngs[lng].nativeName}
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
