import { Dispatch } from "react";
import { withClass } from "../../helpers";
import { withHover, WithHoverProps } from "../withHover";
import "./Button.css";

export enum ButtonSize {
  Small = "0.5em",
  Medium = "1em",
  Large = "2em",
}
export interface ISVGIcon {
  regular: string;
  hover?: string;
}

export interface IButtonBase {
  title: string;
  disabled?: boolean;
  onClick: Dispatch<any>;
}

export interface IButton extends IButtonBase {
  size?: ButtonSize | string;
  spacing?: ButtonSize | string;
  icon: ISVGIcon;
  className?: string;
}

export interface IButtonProps extends IButton, WithHoverProps {}

function Button(props: IButtonProps) {
  const {
    size: height = ButtonSize.Medium,
    spacing: margin,
    isHovered,
    icon: { regular: defaultIcon, hover: hoverIcon },
    hoverRef,
    onClick,
    title,
    className,
    disabled
  } = props;

  return (
    <input
      src={isHovered ? hoverIcon || defaultIcon : defaultIcon}
      type="image"
      className={`Button${withClass(className)}`}
      alt={title}
      title={title}
      ref={hoverRef}
      onClick={onClick}
      style={{ height, margin: `${margin} 0` }}
      disabled={disabled}
    />
  );
}

export default withHover(Button);
