import { equals, ifElse } from "ramda";
import { isDevMode } from ".";

/**
 * Renders an JSX element when state is true
 * @param el element to render
 * @param state state to verify
 * @returns element to render or null
 */
export const renderWhen = (el: JSX.Element, state: boolean) =>
  ifElse(
    equals(true),
    () => el,
    () => null
  )(state);

/**
 * Renders one of JSX elements basing on provided state
 * @param tupleOfElements tuple of two elements
 * @param state state to verify
 * @returns one of elements to render
 */
export const renderOneOf = (
  tupleOfElements: [JSX.Element, JSX.Element],
  state: boolean
) =>
  ifElse(
    equals(true),
    () => tupleOfElements[0],
    () => tupleOfElements[1]
  )(state);

/**
 * Sets an additional class name for the JSX element
 * based on provided state
 * @param className class name
 * @param state state to verify
 * @returns substring for class atribute
 */
export const withClassWhen = (className: string, state: boolean) =>
  ifElse(
    equals(true),
    () => ` ${className}`,
    () => ""
  )(state);

/**
 * Sets an additional class name for the JSX element
 * based on provided class argument
 * @param className class name or anything
 * @returns substring for class atribute
 */
export const withClass = (className: string | any) =>
  withClassWhen(className, Boolean(className));

/**
 * Renders an JSX element when application run in dev mode
 * @param el element to render
 * @returns element to render or null
 */
export const whenDevMode = (el: any) => renderWhen(el, isDevMode);

/**
 * Renders an JSX element when application run in non-dev mode
 * @param el element to render
 * @returns element to render or null
 */
export const whenProdMode = (el: any) => renderWhen(el, !isDevMode);

/**
 * Renders an empty JSX element as a placeholder
 * @param props object with width and height definition
 * @returns element to render
 */
export const Placeholder = (props: { width?: string; height?: string }) => (
  <div
    style={{ width: props.width || "1px", height: props.height || "1px" }}
  ></div>
);
