import { curry, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import {
  defaultToEmptyArray,
  deRunt,
  withClass,
  withClassWhen,
} from "../../../helpers";
import { IUpdateFormData } from "../../Survey/Survey";
import Slide from "../Slide/Slide";
import "./SkinConditions.css";

export enum skinCondition {
  none = "none",
  acne = "acne",
  rosacea = "rosacea",
  eczema = "eczema",
  other = "other",
}
type SkinConditionTypes = skinCondition[];
export interface ISkinConditions {
  skinConditions?: SkinConditionTypes;
}
interface SkinConditionsProps extends IUpdateFormData {
  initialValues?: ISkinConditions;
  className?: string;
  setValid: (r: boolean) => void;
}
const withClassSelectedWhen = curry(withClassWhen)("selected");

const isValid = (selected: SkinConditionTypes) => selected.length > 0;

function SkinConditions({
  setValid: _setValid,
  updateData: _updateData,
  className,
  initialValues,
}: SkinConditionsProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<SkinConditionTypes>(
    defaultToEmptyArray(initialValues?.skinConditions)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setValid = useCallback(_setValid, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    setValid(isValid(selected));
    updateData({ skinConditions: selected });
  }, [selected, setValid, updateData]);

  const handleChange = (e: any) => {
    const {
      checked,
      dataset: { value },
    } = e.target;
    const selectedValues = new Set(selected);
    if (checked) {
      selectedValues.add(value);
    } else {
      selectedValues.delete(value);
    }
    setSelected([...selectedValues]);
  };

  return (
    <Slide className={`SkinConditions${withClass(className)}`}>
      <p>{deRunt(t("skinConditions.description"))}</p>
      <InputGroup className="options">
        {values(skinCondition).map((type) => (
          <Form.Check
            key={type}
            data-value={type}
            id={`type-${type}-id`}
            type="checkbox"
            name="skinConditions"
            label={t(`skinConditions.${type}`)}
            value={selected}
            onChange={handleChange}
            className={`skinConditionsOption${withClassSelectedWhen(
              selected.includes(type)
            )}`}
          />
        ))}
      </InputGroup>
    </Slide>
  );
}

export default SkinConditions;
