import { defaultTo, inc } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultToNegative, isPresent, withClassWhen } from "../../helpers";
import ActionBar from "../ActionBar/ActionBar";
import BasicInfo, { IBasicInfoData } from "../Forms/BasicInfo/BasicInfo";
import NearlyThere from "../Forms/NearlyThere/NearlyThere";
import SkinConditions, {
  ISkinConditions,
} from "../Forms/SkinConditions/SkinConditions";
import SkinConditionsRate, {
  ISkinConditionRate,
} from "../Forms/SkinConditionsRate/SkinConditionsRate";
import SkinIrritation, {
  ISkinIrritation,
} from "../Forms/SkinIrritation/SkinIrritation";
import SkinIrritationFactors1, {
  ISkinIrritationFactors1,
} from "../Forms/SkinIrritationFactors1/SkinIrritationFactors1";
import SkinIrritationFactors2, {
  ISkinIrritationFactors2,
} from "../Forms/SkinIrritationFactors2/SkinIrritationFactors2";
import SkinIrritationFactors3, {
  ISkinIrritationFactors3,
} from "../Forms/SkinIrritationFactors3 /SkinIrritationFactors3";
import SkinType, { ISkinType } from "../Forms/SkinType/SkinType";
import VisibleSkinCondition, {
  IVisibleSkinCondition,
} from "../Forms/VisibleSkinCondition/VisibleSkinCondition";
import { IShowInfoContent } from "../Info/Info";
import Processing from "../Processing/Processing";
import Products from "../Products/Products";
import Progress from "../Progress/Progress";
import Results from "../Results/Results";
import Welcomer from "../Welcomer/Welcomer";
import {
  getActionInfoButton,
  getActionNextButton,
  getActionPrevButton,
} from "./buttonHelper";
import { calculateScore, pickScored } from "./dataMgmt";
import { determineDegreeOfSkinSensitivity } from "./sensitivityCalc";
import {
  getSlidesMap,
  initialProgress,
  initialSlideId,
  isSlide,
  slideToBackground,
} from "./slidesMap";
import "./Survey.css";

const defaultToInitialSlide = defaultTo(initialSlideId);
const defaultToInitialProgress = defaultTo(initialProgress);

export interface IFormData
  extends IBasicInfoData,
    ISkinType,
    ISkinConditions,
    ISkinConditionRate,
    ISkinIrritation,
    ISkinIrritationFactors1,
    ISkinIrritationFactors2,
    ISkinIrritationFactors3,
    IVisibleSkinCondition {
  score?: number;
}

type IUserInfo =
  | IBasicInfoData
  | ISkinType
  | ISkinConditions
  | ISkinConditionRate
  | ISkinIrritation
  | ISkinIrritationFactors1
  | ISkinIrritationFactors2
  | ISkinIrritationFactors3
  | IVisibleSkinCondition;
export interface IUpdateFormData {
  updateData: (info: IUserInfo) => void;
}

interface SurveyProps extends IShowInfoContent {
  start: (status: boolean) => void;
  setScrollable: any;
  setBgColor?: any;
}

function Survey({
  showInfo,
  start: _start,
  setScrollable: _setScrollable,
  setBgColor: _setBgColor,
}: SurveyProps) {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(initialSlideId);
  const [progress, setProgress] = useState<number>(initialProgress);
  const [score, setScore] = useState(0);
  const [data, setData] = useState<IFormData>({});
  const [scored, setScored] = useState<unknown[][]>([]);
  const [formValid, setFormValid] = useState<boolean | undefined>();
  const [sensitivity, setSensitivity] = useState("");
  const slidesMap = getSlidesMap(t);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const start = useCallback(_start, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setScrollable = useCallback(_setScrollable, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setBgColor = useCallback(_setBgColor, []);

  useEffect(() => {
    // empty data on init and on restart
    if (step === initialSlideId) {
      setData({});
    }
  }, [step]);

  useEffect(() => {
    const isSlideStatus = isSlide(step);
    // mark survey as started (enables progress bar, action buttons etc)
    start(isSlideStatus);
  }, [step, start]);

  useEffect(() => {
    // make screen scrollable for product's page
    setScrollable(step === 13);
  }, [step, setScrollable]);

  useEffect(() => {
    // when survey completed, calculate sensitivity
    if (slidesMap.get(step)?.completed) {
      setSensitivity(determineDegreeOfSkinSensitivity(score));
    }
  }, [step, slidesMap, score]);

  useEffect(() => {
    // set app's background color basing on step number
    setBgColor(slideToBackground(step));
  }, [step, setBgColor]);

  useEffect(() => {
    const scoredData = pickScored(data);
    setScore(calculateScore(scoredData));
    setScored(scoredData);
  }, [data]);

  const currentSlide = slidesMap.get(step);
  const nextSlide = slidesMap.get(step + 1);
  const prevId = defaultToInitialSlide(currentSlide?.prev);
  const prevProgress = defaultToInitialProgress(
    slidesMap.get(prevId)?.progress
  );
  const prevButton = isPresent(currentSlide?.prev)
    ? getActionPrevButton({
        onClick: () => {
          setStep(prevId);
          setProgress(prevProgress);
        },
        title: t("actions.prev"),
      })
    : undefined;
  const nextId = defaultToInitialSlide(currentSlide?.next);
  const nextProgress = defaultToInitialProgress(
    slidesMap.get(nextId)?.progress
  );
  const nextButton = isPresent(currentSlide?.next)
    ? getActionNextButton({
        onClick: () => {
          setStep(nextId);
          setProgress(nextProgress);
        },
        title: t("actions.next"),
        disabled: !formValid,
      })
    : undefined;
  const info = currentSlide?.info || null;
  const infoButton = isPresent(info)
    ? getActionInfoButton({
        onClick: () => showInfo(info),
        title: t("actions.info"),
      })
    : undefined;

  const setValid = (result: boolean) => setFormValid(result);
  const updateData = (info: IUserInfo) => setData({ ...data, ...info });

  return (
    <div className={`Survey${withClassWhen("slide", step !== initialSlideId)}`}>
      <Progress status={progress} />
      {(() => {
        const {
          name,
          age,
          sex,
          skinType,
          skinConditions = [],
          skinConditionRate,
          scored_skinIrritation,
          scored_burning,
          scored_tingling,
          scored_sensationsOfHeat,
          scored_tautness,
          scored_itching,
          scored_pain,
          scored_generalDiscomfort,
          scored_hotFlashes,
          scored_redness,
        } = data;
        switch (step) {
          case 1:
            return (
              <BasicInfo
                initialValues={{
                  name,
                  age,
                  sex,
                }}
                setValid={setValid}
                updateData={updateData}
              />
            );
          case 2:
            return (
              <SkinType
                initialValues={{ skinType }}
                setValid={setValid}
                updateData={updateData}
              />
            );
          case 3:
            return (
              <SkinConditions
                initialValues={{ skinConditions }}
                setValid={setValid}
                updateData={updateData}
              />
            );
          case 4:
            return (
              <SkinConditionsRate
                initialValues={{
                  skinConditionRate: defaultToNegative(skinConditionRate),
                }}
                setValid={setValid}
                updateData={updateData}
              />
            );
          case 5:
            return <NearlyThere setValid={setValid} />;
          case 6:
            return (
              <SkinIrritation
                initialValues={{ scored_skinIrritation }}
                setValid={setValid}
                updateData={updateData}
              />
            );
          case 7:
            return (
              <SkinIrritationFactors1
                initialValues={{
                  scored_tingling,
                  scored_burning,
                  scored_sensationsOfHeat,
                }}
                setValid={setValid}
                updateData={updateData}
                showInfo={showInfo}
              />
            );
          case 8:
            return (
              <SkinIrritationFactors2
                initialValues={{ scored_tautness, scored_itching, scored_pain }}
                setValid={setValid}
                updateData={updateData}
                showInfo={showInfo}
              />
            );
          case 9:
            return (
              <SkinIrritationFactors3
                initialValues={{ scored_generalDiscomfort, scored_hotFlashes }}
                setValid={setValid}
                updateData={updateData}
                showInfo={showInfo}
              />
            );
          case 10:
            return (
              <VisibleSkinCondition
                initialValues={{ scored_redness }}
                setValid={setValid}
                updateData={updateData}
                showInfo={showInfo}
              />
            );
          case 11:
            return <Processing next={() => setStep(inc(step))} />;
          case 12:
            return (
              <Results
                rawData={data}
                data={scored}
                score={score}
                sensitivity={sensitivity}
                showInfo={showInfo}
                next={() => setStep(inc(step))}
                setScrollable={setScrollable}
              />
            );
          case 13:
            return (
              <Products
                sensitivity={sensitivity}
                next={() => setStep(initialSlideId)}
              />
            );
          default:
            return (
              <Welcomer
                next={() => {
                  setStep(initialSlideId + 1);
                  setProgress(defaultToInitialProgress(nextSlide?.progress));
                }}
                showInfo={showInfo}
              />
            );
        }
      })()}
      <ActionBar
        show={isSlide(step)}
        prevButton={prevButton}
        nextButton={nextButton}
        infoButton={infoButton}
      />
    </div>
  );
}

export default Survey;
