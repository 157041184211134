import { equals, is, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultToNegative,
  deRunt,
  withClass,
  withClassWhen,
} from "../../../helpers";
import { IUpdateFormData } from "../../Survey/Survey";
import Slide from "../Slide/Slide";
import VerticalSlider from "../VerticalSlider/VerticalSlider";
import "./SkinConditionsRate.css";

export enum skinConditionRate {
  notSensitive = 0,
  slightlySensitive,
  sensitive,
  verySensitive,
}
export const skinConditionToKey = (value: number) => skinConditionRate[value];
export const defaultSkinConditionValue = -1;
export interface ISkinConditionRate {
  skinConditionRate?: number;
}

interface SkinConditionsRateProps extends IUpdateFormData {
  initialValues?: { skinConditionRate: number };
  className?: string;
  setValid: (r: boolean) => void;
}

const isValid = (value: number | undefined) =>
  is(Number)(value) ? values(skinConditionRate).includes(value) : false;

function SkinConditionsRate({
  setValid: _setValid,
  updateData: _updateData,
  className,
  initialValues,
}: SkinConditionsRateProps) {
  const { t } = useTranslation();
  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState(
    defaultToNegative(initialValues?.skinConditionRate)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setValid = useCallback(_setValid, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    setValid(isValid(value));
    updateData({ skinConditionRate: value });
    setTouched(value >= 0);
  }, [value, setValid, updateData]);

  const handleChange = (value: number) => {
    setValue(value);
    setTouched(true);
  };
  const withClassSelected = (rate: skinConditionRate) =>
    withClassWhen("selected", equals(value, rate));

  return (
    <Slide className={`SkinConditionsRate${withClass(className)}`}>
      <p>
        {deRunt(t("skinConditions2.description"))}
        <span className="preload"></span>
        <span className="preload-italic"></span>
      </p>
      <div className="slider vertical">
        <div className="labels left">
          <div
              className={`option${withClassSelected(skinConditionRate.verySensitive)}`}
              onClick={() => setValue(skinConditionRate.verySensitive)}>{t("skinConditions2.verySensitive")}</div>
          <div className="option">&nbsp;</div>
          <div
              className={`option${withClassSelected(skinConditionRate.slightlySensitive)}`}
              onClick={() => setValue(skinConditionRate.slightlySensitive)}>{t("skinConditions2.slightlySensitive")}</div>
          <div className="option">&nbsp;</div>
        </div>
        <VerticalSlider
          value={value}
          min={skinConditionRate.notSensitive}
          max={skinConditionRate.verySensitive}
          className={withClassWhen("touched", touched)}
          handleChange={handleChange}
          noMarkers
        />
        <div className="labels right">
          <div className="option">&nbsp;</div>
          <div
            className={`option${withClassSelected(skinConditionRate.sensitive)}`}
            onClick={() => setValue(skinConditionRate.sensitive)}>{t("skinConditions2.sensitive")}</div>
          <div className="option">&nbsp;</div>
          <div
            className={`option${withClassSelected(skinConditionRate.notSensitive)}`}
            onClick={() => setValue(skinConditionRate.notSensitive)}>{t("skinConditions2.notSensitive")}</div>
        </div>
      </div>
    </Slide>
  );
}

export default SkinConditionsRate;
