import { renderOneOf, renderWhen } from "../../helpers";
import logo3x from "../../assets/logo@3x.png";
import "./Logo.css";

export enum LogoSize {
  Small = "0.5em",
  Medium = "37px",
  Large = "43px",
}
interface LogoProps {
  size?: LogoSize | string;
  color?: string;
  subtitle?: string;
  textMode?: boolean;
  inlineMode?: boolean;
}

function Logo(props: LogoProps) {
  const {
    color,
    size: fontSize = LogoSize.Large,
    size: height,
    subtitle,
    textMode = false,
    inlineMode = false
  } = props;

  return (
    <>
      {renderOneOf(
        [
          renderOneOf(
            [
              <span className="Logo" style={{ fontSize, color }}>
                Sensi<span>Scale</span>
              </span>,
              <h1 className="Logo" style={{ fontSize, color }}>
                Sensi<span>Scale</span>
              </h1>,
            ],
            inlineMode
          ),
          <img
            src={logo3x}
            className="Logo"
            style={{ height }}
            alt="SensiScale"
            title="SensiScale"
          />,
        ],
        textMode
      )}

      {renderWhen(<h2>{subtitle}</h2>, Boolean(subtitle))}
    </>
  );
}

export default Logo;
