import { curry, equals, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from "react-i18next";
import { deRunt, withClass, withClassWhen } from "../../../helpers";
import { IUpdateFormData } from "../../Survey/Survey";
import Slide from "../Slide/Slide";
import "./SkinType.css";

export enum skinType {
  dry = "dry",
  normal = "normal",
  oily = "oily",
  mixed = "mixed",
}
type SkinTypeOrNone = skinType | undefined
export interface ISkinType {
  skinType?: SkinTypeOrNone
}
interface SkinTypeProps extends IUpdateFormData {
  initialValues?: ISkinType;
  className?: string;
  setValid: (r: boolean) => void;
}
const isSkinType = equals<SkinTypeOrNone>
const withClassSelectedWhen = curry(withClassWhen)("selected")

const isValid = (selected: SkinTypeOrNone) => selected ? values(skinType).includes(selected) : false

function SkinType({setValid: _setValid, updateData: _updateData, className, initialValues}: SkinTypeProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<SkinTypeOrNone>(initialValues?.skinType);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setValid = useCallback(_setValid, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    setValid(isValid(selected))
    updateData({skinType: selected})
  },[selected, setValid, updateData])
  
  return (
    <Slide className={`SkinType${withClass(className)}`}>
      <p>{deRunt(t("skinType.description"))}</p>
      <InputGroup className="options">
        {values(skinType).map((type) => (<Form.Check
          key={type}
          id={`type-${type}-id`}
          type="radio"
          name="skinType"
          label={t(`skinType.${type}`)}
          value={selected}
          onChange={() => setSelected(type)}
          className={`skinTypeOption${withClassSelectedWhen(isSkinType(type)(selected))}`}
        />))}
      </InputGroup>
    </Slide>
  );
}

export default SkinType;
