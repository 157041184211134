import { any, flip, includes, is, range } from "ramda";

export const shuffle = (arr: Array<any>): Array<any> =>
  arr.sort(() => Math.random() - 0.5);

export const rangeTo = (max: number): Array<number> => range(1, max + 1);

export const anyExists = any(Boolean);

export const contains = flip(includes)

export const isArray = is(Array)