import React, { useRef } from "react";
import useHover from "../hooks/useHover";

export interface WithHoverProps {
  isHovered: boolean;
  hoverRef: React.MutableRefObject<null>;
}

export const withHover = <T extends WithHoverProps = WithHoverProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const ComponentWithHover = (props: Omit<T, keyof WithHoverProps>) => {
    const hoverRef = useRef(null);
    const isHovered = useHover(hoverRef);

    return <WrappedComponent {...{ isHovered, hoverRef }} {...(props as T)} />;
  };
  ComponentWithHover.displayName = `withHover(${displayName})`;

  return ComponentWithHover;
};
