import { either, equals } from "ramda";
import "rc-slider/assets/index.css";
import { lazy, useEffect, useState } from "react";
import { isTruthy, withClass, withClassWhen } from "../../../helpers";
import "./VerticalSlider.css";

const RCSlider = lazy(
  () => import(/* webpackChunkName: "rc-slider" */ "rc-slider")
);
export const isInitialOrEq0 = either(equals(-1), equals(0));
export const equals10 = equals(10);

interface VerticalSliderProps {
  min?: number;
  max?: number;
  value: number;
  handleChange: (e: any) => void;
  className?: string;
  noMarkers?: boolean;
}

function VerticalSlider({
  className = "",
  handleChange,
  max = 10,
  min = 0,
  noMarkers,
  value,
}: VerticalSliderProps) {
  const [mark_0, setMark_0] = useState<number | undefined>(0);
  const [mark_10, setMark_10] = useState<number | undefined>(10);

  useEffect(() => {
    setMark_0(isInitialOrEq0(value) ? undefined : 0);
    setMark_10(equals10(value) ? undefined : 10);
  }, [value]);

  return (
    <div className={`VerticalSlider${withClass(className)}`}>
      <div className="range">
        <RCSlider
          vertical
          min={min}
          max={max}
          value={value}
          className={`rcslider${withClassWhen("no-markers", isTruthy(noMarkers))}`}
          dots={true}
          marks={noMarkers ? undefined : { 0: mark_0, 10: mark_10 }}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default VerticalSlider;
