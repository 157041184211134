import i18next from "i18next";
import { IFormData } from "../components/Survey/Survey";
import { capitalize, formatDate } from "./helpers";

const toLabel = (key: string, index: number) =>
  `${index}. ${i18next.t("results.complaints." + key)}`;

export type pdfInputType = IFormData & {
  scoreLabel: string;
  sorted: any[];
  sensitivityLabel: string;
  chart: any;
};

export const mapDataToInputs = (
  data: pdfInputType
): Record<string, string> => ({
  today: formatDate(new Date()),
  score: data.scoreLabel,
  name: capitalize(String(data.name)),
  age: String(data.age),
  sex: capitalize(String(data.sex)),
  majorComplaints: i18next.t("results.top3MajorPdf", {
    skinType: i18next.t("results.verdict.degrees." + data.sensitivityLabel),
  }),
  complaint1: toLabel(data.sorted[0][0], 1),
  complaint2: toLabel(data.sorted[1][0], 2),
  complaint3: toLabel(data.sorted[2][0], 3),
  complaint4: toLabel(data.sorted[3][0], 4),
  complaint5: toLabel(data.sorted[4][0], 5),
  complaint6: toLabel(data.sorted[5][0], 6),
  complaint7: toLabel(data.sorted[6][0], 7),
  complaint8: toLabel(data.sorted[7][0], 8),
  complaint9: toLabel(data.sorted[8][0], 9),
  complaint10: toLabel(data.sorted[9][0], 10),
  chartBg: " ",
  chart: data.chart,
});

export const generatePdf = async (input: pdfInputType) => {
  import(/* webpackChunkName: "template" */ "./template.json").then(
    (template) => {
      import(/* webpackChunkName: "pdfme" */ "@pdfme/generator").then(
        async ({ generate }) => {
          const inputs = [mapDataToInputs(input)];
          const pdf = await generate({ template: template as any, inputs });
          const blob = new Blob([pdf.buffer], { type: "application/pdf" });
          window.open(URL.createObjectURL(blob));
        }
      );
    }
  );
};
