import { TFunction } from "i18next";
import { cond, equals, always, T, allPass } from "ramda";
import { InfoContent } from "../Info/Info";

export const slideToBackground = cond([
  [equals(0), always("#D6EFF7")],
  [equals(11), always("#D6EFF7")],
  [equals(12), always("#D6EFF7")],
  [equals(13), always("#FCFEFF")],
  [T, always("#FFFFFF")]
])

export const isSlide = allPass([
  (v: number) => v > initialSlideId,
  (v: number) => v < 11,
]); // TODO: dynamically get 'Processing' slide id

export interface ISlide {
  className?: string;
  info?: InfoContent;
  name?: string;
  next?: number;
  prev?: number;
  progress: number;
  completed?: boolean;
}

export const initialSlideId = 0;
export const lastSlideIdWithInputs = 10;
export const initialProgress = -1;

export const getSlidesMap = (tFn: TFunction) =>
  new Map<number, ISlide>([
    [initialSlideId, { progress: initialProgress }],
    [
      1,
      {
        className: "Basic-info",
        info: {
          paragraph: tFn("basicInfo.info.content"),
          title: tFn("basicInfo.info.title"),
          withHeaderLogo: false,
        },
        name: "Basic info",
        next: 2,
        prev: initialSlideId,
        progress: 5,
      },
    ],
    [
      2,
      {
        className: "Skin-type",
        name: "Skin type",
        next: 3,
        prev: 1,
        progress: 10,
      },
    ],
    [
      3,
      {
        className: "Skin-conditions",
        name: "Skin conditions",
        next: 4,
        prev: 2,
        progress: 25,
      },
    ],
    [
      4,
      {
        className: "Skin-conditions-2",
        name: "Skin conditions - self diagnosis",
        next: 5,
        prev: 3,
        progress: 40,
      },
    ],
    [
      5,
      {
        className: "Nearly-there",
        info: {
          paragraph: tFn("nearlyThere.info.content"),
          title: tFn("nearlyThere.info.title"),
          withHeaderLogo: false,
        },
        name: "Nearly there",
        next: 6,
        prev: 4,
        progress: 50,
      },
    ],
    [
      6,
      {
        className: "Overall-skin-irritation",
        info: {
          paragraph: tFn("overallSkinIrritation.info.content"),
          title: tFn("overallSkinIrritation.info.title"),
          withHeaderLogo: false,
        },
        name: "Overall skin irritation",
        next: 7,
        prev: 5,
        progress: 55,
      },
    ],
    [
      7,
      {
        className: "Irritation-factors-1",
        name: "Irritation factors 1",
        next: 8,
        prev: 6,
        progress: 60,
      },
    ],
    [
      8,
      {
        className: "Irritation-factors-2",
        name: "Irritation factors 2",
        next: 9,
        prev: 7,
        progress: 70,
      },
    ],
    [
      9,
      {
        className: "Irritation-factors-3",
        name: "Irritation factors 3",
        next: lastSlideIdWithInputs,
        prev: 8,
        progress: 80,
      },
    ],
    [
      lastSlideIdWithInputs,
      {
        className: "Visible-skin-condition",
        name: "Visible skin condition",
        next: 11,
        prev: 9,
        progress: 90,
      },
    ],
    [
      11,
      {
        className: "Processing",
        completed: true,
        name: "Processing",
        next: 12,
        prev: lastSlideIdWithInputs,
        progress: 100,
      },
    ],
  ]);
