const suffixMap = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
};
const locale = "en-GB";
const pluralRule = new Intl.PluralRules(locale, {
  type: "ordinal",
});

export const formatDate = (date: Date) => {
  const dayOfMonth = date.getDate();
  const month = date.toLocaleString(locale, { month: "long" });
  const year = date.getFullYear();
  const val = pluralRule.select(dayOfMonth);
  if (val !== "zero" && val !== "many") {
    return `${dayOfMonth}${suffixMap[val]} ${month} ${year}`;
  }
  return `${dayOfMonth} ${month} ${year}`;
};

export const capitalize = (str: string): string =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
