import "rc-slider/assets/index.css";
import { lazy } from "react";
import RangeScale from "../RangeScale/RangeScale";
import "./Slider.css";

const RCSlider = lazy(
  () => import(/* webpackChunkName: "rc-slider" */ "rc-slider")
);

interface SliderProps {
  value: number;
  label: string;
  handleChange: (e: any) => void;
  handleShowInfo: (e: any) => void;
}

function Slider({
  handleChange,
  label,
  handleShowInfo: showInfo,
  value,
}: SliderProps) {
  return (
    <div key={label} className="Slider">
      <p className="label" onClick={showInfo}>
        {label}
      </p>
      <div className="range">
        <RCSlider
          key={label}
          min={0}
          max={10}
          value={value}
          className="rcslider"
          dots={true}
          onChange={handleChange}
        />
        {/* TODO: try to use build-in marks
          https://slider-react-component.vercel.app/demo/marks#marks */}
        <RangeScale currentValue={value} />
      </div>
    </div>
  );
}

export default Slider;
