import { compose, curry, defaultTo } from "ramda";
import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import {
  defaultToEmptyObj,
  defaultToEmptyString,
  deRunt,
  isTruthy,
  withClass,
  withClassWhen,
} from "../../../helpers";
import SexAccordion from "../../SexAccordion/SexAccordion";
import { IUpdateFormData } from "../../Survey/Survey";
import Slide from "../Slide/Slide";
import "./BasicInfo.css";

export enum Sex {
  male = "male",
  female = "female",
  other = "other",
}
const cWithClassWhen = curry(withClassWhen)("with-content");
const withContentWhen = compose(cWithClassWhen, isTruthy);

const isValid = ({ name, age, sex }: IBasicInfoData) =>
  Boolean(name && age && sex) &&
  Boolean(age && age > 0 && age <= 100) &&
  Number.isInteger(Number(age));

export interface IBasicInfoData {
  name?: string | null;
  age?: number | null;
  sex?: Sex | null;
}

interface BasicInfoProps extends IUpdateFormData {
  initialValues?: IBasicInfoData;
  className?: string;
  setValid: (r: boolean) => void;
}

function BasicInfo({
  setValid: _setValid,
  updateData: _updateData,
  className,
  initialValues,
}: BasicInfoProps) {
  const { t } = useTranslation();
  const [data, setData] = useState<IBasicInfoData>(
    defaultToEmptyObj(initialValues)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setValid = useCallback(_setValid, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    setValid(isValid(data));
    updateData(data);
  }, [data, setValid, updateData]);

  const handleUserInput = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSexAccordionChange = (sex: any) => {
    setData({ ...data, sex });
  };

  return (
    <Slide className={`BasicInfo${withClass(className)}`}>
      <p>{deRunt(t("basicInfo.description"))}</p>
      <Form.Control
        className={`input-text input-area${withContentWhen(data.name)}`}
        type="textMode"
        placeholder={t("basicInfo.name.placeholder")}
        name="name"
        defaultValue={defaultToEmptyString(data.name)}
        onChange={handleUserInput}
      ></Form.Control>
      <Form.Control
        className={`input-text input-area${withContentWhen(data.age)}`}
        type="number"
        placeholder={t("basicInfo.age.placeholder")}
        name="age"
        defaultValue={defaultToEmptyString(data.age)}
        onChange={handleUserInput}
      ></Form.Control>
      <SexAccordion
        defaultValue={defaultTo(null)(data.sex)}
        onChange={handleSexAccordionChange}
      />
    </Slide>
  );
}

export default BasicInfo;
