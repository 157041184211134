
export * from "./arrays";
export * from "./chart";
export * from "./colors";
export * from "./component";
export * from "./i18n";
export * from "./logic";
export * from "./typography";

// env
export const isDevMode = process.env.REACT_APP_DEV === "true";

// candidates for misc.ts
export const randomBetween = ({ min = 0, max = 100 }) =>
  Math.floor(Math.random() * (max - min) + min);
