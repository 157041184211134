import { compose, lensProp, set, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  allAreTrue,
  defaultToNegative,
  deRunt,
  withClass,
  withClassWhen,
} from "../../../helpers";
import { IUpdateFormData } from "../../Survey/Survey";
import Legend from "../Legend/Legend";
import Slide from "../Slide/Slide";
import VerticalSlider from "../VerticalSlider/VerticalSlider";
import "./SkinIrritation.css";

export interface ISkinIrritation {
  scored_skinIrritation?: number;
}
type touchedSliders = {
  skinIrritation: boolean;
};
const skinIrritationTouched = lensProp<touchedSliders>("skinIrritation");

interface SkinIrritationProps extends IUpdateFormData {
  initialValues?: ISkinIrritation;
  className?: string;
  setValid: (r: boolean) => void;
}

function SkinIrritation({
  setValid,
  updateData: _updateData,
  className,
  initialValues,
}: SkinIrritationProps) {
  const { t } = useTranslation();
  const [skinIrritationValue, setSkinIrritationValue] = useState<number>(
    defaultToNegative(initialValues?.scored_skinIrritation)
  );
  const [touched, setTouched] = useState<touchedSliders>({
    skinIrritation: skinIrritationValue >= 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(compose(setValid, allAreTrue, values), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    updateData({ scored_skinIrritation: skinIrritationValue });
  }, [skinIrritationValue, updateData]);
  useEffect(() => {
    validate(touched);
  }, [touched, validate]);

  const handleChange = (value: number) => {
    setSkinIrritationValue(value);
    setTouched(set(skinIrritationTouched, true));
  };

  return (
    <Slide className={`SkinIrritation${withClass(className)}`}>
      <p>{deRunt(t("overallSkinIrritation.description"))}</p>
      <Legend content={t("overallSkinIrritation.legend")} />
      <VerticalSlider
        value={skinIrritationValue}
        handleChange={handleChange}
        className={withClassWhen("touched", true)}
      />
    </Slide>
  );
}

export default SkinIrritation;
