import { always } from "ramda";
import {
  anyExists,
  Placeholder,
  renderOneOf,
  renderWhen,
  withClass,
} from "../../helpers";
import Button, { IButton } from "../Button/Button";
import "./ActionBar.css";

export interface IActionBar {
  className?: string;
  show?: boolean;
  prevButton?: IButton;
  infoButton?: IButton;
  nextButton?: IButton;
}

export interface ActionBarProps extends IActionBar {}

function ActionBar(props: ActionBarProps) {
  const { className, show, prevButton, infoButton, nextButton } = props;
  const actions = [prevButton, infoButton, nextButton];

  return renderWhen(
    <div className={`ActionBar${withClass(className)}`}>
      {actions.map((action, key) =>
        renderOneOf(
          [
            <Button
              icon={action?.icon || { regular: "" }}
              onClick={action?.onClick || always}
              className={action?.className}
              key={action?.title}
              size={action?.size}
              spacing={action?.spacing}
              title={action?.title || ""}
              disabled={action?.disabled}
            />,
            <Placeholder key={key} height="60px" width="60px" />,
          ],
          Boolean(action)
        )
      )}
    </div>,
    anyExists(actions) && Boolean(show)
  );
}

export default ActionBar;
