import { compose, lensProp, set, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  allAreTrue,
  defaultToNegative,
  deRunt,
  withClass,
} from "../../../helpers";
import { IShowInfoContent } from "../../Info/Info";
import { IUpdateFormData } from "../../Survey/Survey";
import Legend from "../Legend/Legend";
import Slide from "../Slide/Slide";
import Slider from "../Slider/Slider";
import "./SkinIrritationFactors2.css";

export interface ISkinIrritationFactors2 {
  scored_tautness?: number;
  scored_itching?: number;
  scored_pain?: number;
}
type touchedSliders = {
  tautness: boolean;
  itching: boolean;
  pain: boolean;
};
const tautnessTouched = lensProp<touchedSliders>("tautness");
const itchingTouched = lensProp<touchedSliders>("itching");
const painTouched = lensProp<touchedSliders>("pain");

interface SkinIrritationFactors2Props
  extends IUpdateFormData,
    IShowInfoContent {
  initialValues?: ISkinIrritationFactors2;
  className?: string;
  setValid: (r: boolean) => void;
}

function SkinIrritationFactors2({
  setValid,
  showInfo,
  updateData: _updateData,
  className,
  initialValues,
}: SkinIrritationFactors2Props) {
  const { t } = useTranslation();
  const [tautnessValue, setTautnessValue] = useState<number>(
    defaultToNegative(initialValues?.scored_tautness)
  );
  const [itchingValue, setItchingValue] = useState<number>(
    defaultToNegative(initialValues?.scored_itching)
  );
  const [painValue, setPainValue] = useState<number>(
    defaultToNegative(initialValues?.scored_pain)
  );
  const [touched, setTouched] = useState<touchedSliders>({
    tautness: tautnessValue >= 0,
    itching: itchingValue >= 0,
    pain: painValue >= 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(compose(setValid, allAreTrue, values), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    updateData({
      scored_tautness: tautnessValue,
      scored_itching: itchingValue,
      scored_pain: painValue,
    });
  }, [tautnessValue, itchingValue, painValue, updateData]);
  useEffect(() => {
    validate(touched);
  }, [touched, validate]);

  const handleTautnessChange = (value: number) => {
    setTautnessValue(value);
    setTouched(set(tautnessTouched, true));
  };
  const handleItchingChange = (value: number) => {
    setItchingValue(value);
    setTouched(set(itchingTouched, true));
  };
  const handlePainChange = (value: number) => {
    setPainValue(value);
    setTouched(set(painTouched, true));
  };

  return (
    <Slide className={`SkinIrritationFactors2${withClass(className)}`}>
      <p>{deRunt(t("irritationFactors2.description"))}</p>
      <Legend content={t("irritationFactors2.legend")} />
      {[
        {
          value: tautnessValue,
          label: t("irritationFactors2.tautness"),
          handleChange: handleTautnessChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors2.info1.title"),
              paragraph: t("irritationFactors2.info1.content"),
            }),
        },
        {
          value: itchingValue,
          label: t("irritationFactors2.itching"),
          handleChange: handleItchingChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors2.info2.title"),
              paragraph: t("irritationFactors2.info2.content"),
            }),
        },
        {
          value: painValue,
          label: t("irritationFactors2.pain"),
          handleChange: handlePainChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors2.info3.title"),
              paragraph: t("irritationFactors2.info3.content"),
            }),
        },
      ].map(({ value, label, handleChange, handleShowInfo }) => (
        <Slider
          key={label}
          value={value}
          label={label}
          handleChange={handleChange}
          handleShowInfo={handleShowInfo}
        />
      ))}
    </Slide>
  );
}

export default SkinIrritationFactors2;
