import { useState } from "react";
import "./App.css";
import { InfoContent } from "./components/Info/Info";
import { Layout } from "./components/Layout";
import Survey from "./components/Survey/Survey";

const App = () => {
  const [info, setInfo] = useState<InfoContent | null>(null);
  const [started, setStarted] = useState<boolean>(false);
  const [bgColor, setBgColor] = useState<string>("#E5F5FB");
  const [scrollable, setScrollable] = useState<boolean>(false);

  return (
    <>
      <div className="App">
        <Layout
          info={info}
          showInfo={setInfo}
          started={started}
          bgColor={bgColor}
          scrollable={scrollable}
        >
          <Survey
            showInfo={setInfo}
            start={(status: boolean) => setStarted(status)}
            setScrollable={setScrollable}
            setBgColor={setBgColor}
          />
        </Layout>
      </div>
    </>
  );
};

export default App;
