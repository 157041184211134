import {
  compose,
  flip,
  map,
  nth,
  pickBy,
  split,
  startsWith,
  sum,
  toPairs,
  values,
} from "ramda";

// remove "scored_" prefix
export const stripComplaintName = compose(nth(1), split("_"));
export const startsWithScored = flip(startsWith("scored_"));
export const pickScored = compose(
  map(([key, value]) => [stripComplaintName(key), value]),
  toPairs,
  pickBy(startsWithScored)
);
export const calculateScore = compose(
  sum,
  map(([_, v]: [any, number]) => v),
  values
);
