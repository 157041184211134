import { always, prop } from "ramda";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import arrowDown from "../../assets/arrowDown.svg";
import logo from "../../assets/cetaphil-logo.svg";
import { defaultToEmptyString } from "../../helpers";
import { determineColorById } from "../Complaint/Complaint";
import TextButton from "../TextButton/TextButton";
import WhiteBox from "../WhiteBox/WhiteBox";
import "./Products.css";
import { pickProductValues } from "./utils";

interface ProductsProps {
  sensitivity: string;
  next?: any;
}

function Products({ sensitivity, next }: ProductsProps) {
  const { t } = useTranslation();
  const [products, setProducts] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    Promise.all([
      import(/* webpackChunkName: "product-images" */ "../../assets/products/302990241334_Search.png"),
      import(/* webpackChunkName: "product-images" */ "../../assets/products/302990241334_Search.png"),
      import(/* webpackChunkName: "product-images" */ "../../assets/products/302990241334_Search.png"),
      import(/* webpackChunkName: "product-images" */ "../../assets/products/302990241334_Search.png"),
      import(/* webpackChunkName: "product-images" */ "../../assets/products/302990241334_Search.png"),
    ]).then((imgs) => {
      setImages(imgs.map(prop("default")));
    });
  }, []);

  useEffect(() => {
    import("./products").then(
      ({ products }) => {
        setProducts(pickProductValues(products));
      }
    );
  }, []);

  const TimePeriod = always(<span>{t("products.timePeriod")}</span>);

  return (
    <div className="Products">
      <div className="wrapper">
        <img className="logo" width="135" height="77" src={logo} alt="logo" />
        <h4>
          <Trans
            i18nKey="products.title"
            t={t}
            components={[t("results.verdict.degrees." + sensitivity)]}
          />
        </h4>
        <p className="description">
          <Trans
            i18nKey="products.description"
            t={t}
            components={[
              t("results.verdict.degrees." + sensitivity),
              <TimePeriod />,
            ]}
          />
        </p>
        <div className="prd">
          {products.map((p, i) => (
            <WhiteBox key={p.name + i} className="suggestion">
              <div className="text-content">
                <h5>{p.name}</h5>
                <div className="complaints">
                  {p.skinType[sensitivity].map((c: any, i: number) => (
                    <div key={c.name + i} className="complaint">
                      <img
                        className="arrow"
                        width="16"
                        height="16"
                        src={arrowDown}
                        alt="Decrease"
                      />
                      <div className="complaint-name">{c.name}</div>
                      <div
                        className="complaint-decrease"
                        style={{ color: determineColorById(i) }}
                      >
                        {c.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <img
                width="122"
                height="122"
                className="product-image"
                alt="product-placeholder"
                src={defaultToEmptyString(images[i])}
              />
            </WhiteBox>
          ))}
        </div>
        <TextButton
          className="btn"
          url="https://www.cetaphil.com/us/"
          label={t("products.learnMore")}
          isLink
        />
        <TextButton
          className="btn"
          onClick={next}
          label={t("products.restart")}
        />
      </div>
    </div>
  );
}

export default Products;
