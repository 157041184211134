import React from "react";
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher";
import "./Footer.css";

interface FooterProps {
  // size?: string;
}

function Footer(props: FooterProps) {
  return (
    <footer className="Footer">
      <LanguageSwitcher />
    </footer>
  );
}

export default Footer;
