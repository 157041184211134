import { compose, lensProp, set, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  allAreTrue,
  defaultToNegative,
  deRunt,
  withClass,
} from "../../../helpers";
import { IShowInfoContent } from "../../Info/Info";
import { IUpdateFormData } from "../../Survey/Survey";
import Legend from "../Legend/Legend";
import Slide from "../Slide/Slide";
import Slider from "../Slider/Slider";
import "./SkinIrritationFactors3.css";

export interface ISkinIrritationFactors3 {
  scored_generalDiscomfort?: number;
  scored_hotFlashes?: number;
}
type touchedSliders = {
  generalDiscomfort: boolean;
  hotFlashes: boolean;
};
const generalDiscomfortTouched = lensProp<touchedSliders>("generalDiscomfort");
const hotFlashesTouched = lensProp<touchedSliders>("hotFlashes");

interface SkinIrritationFactors3Props
  extends IUpdateFormData,
    IShowInfoContent {
  initialValues?: ISkinIrritationFactors3;
  className?: string;
  setValid: (r: boolean) => void;
}

function SkinIrritationFactors3({
  setValid,
  showInfo,
  updateData: _updateData,
  className,
  initialValues,
}: SkinIrritationFactors3Props) {
  const { t } = useTranslation();
  const [generalDiscomfortValue, setGeneralDiscomfortValue] = useState<number>(
    defaultToNegative(initialValues?.scored_generalDiscomfort)
  );
  const [hotFlashesValue, setHotFlashesValue] = useState<number>(
    defaultToNegative(initialValues?.scored_hotFlashes)
  );
  const [touched, setTouched] = useState<touchedSliders>({
    generalDiscomfort: generalDiscomfortValue >= 0,
    hotFlashes: hotFlashesValue >= 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(compose(setValid, allAreTrue, values), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    updateData({
      scored_generalDiscomfort: generalDiscomfortValue,
      scored_hotFlashes: hotFlashesValue,
    });
  }, [generalDiscomfortValue, hotFlashesValue, updateData]);
  useEffect(() => {
    validate(touched);
  }, [touched, validate]);

  const handleGeneralDiscomfortChange = (value: number) => {
    setGeneralDiscomfortValue(value);
    setTouched(set(generalDiscomfortTouched, true));
  };
  const handleHotFlashesChange = (value: number) => {
    setHotFlashesValue(value);
    setTouched(set(hotFlashesTouched, true));
  };

  return (
    <Slide className={`SkinIrritationFactors3${withClass(className)}`}>
      <p>{deRunt(t("irritationFactors3.description"))}</p>
      <Legend content={t("irritationFactors3.legend")} />
      {[
        {
          value: generalDiscomfortValue,
          label: t("irritationFactors3.generalDiscomfort"),
          handleChange: handleGeneralDiscomfortChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors3.info1.title"),
              paragraph: t("irritationFactors3.info1.content"),
            }),
        },
        {
          value: hotFlashesValue,
          label: t("irritationFactors3.hotFlashes"),
          handleChange: handleHotFlashesChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors3.info2.title"),
              paragraph: t("irritationFactors3.info2.content"),
            }),
        },
      ].map(({ value, label, handleChange, handleShowInfo }) => (
        <Slider
          key={label}
          value={value}
          label={label}
          handleChange={handleChange}
          handleShowInfo={handleShowInfo}
        />
      ))}
    </Slide>
  );
}

export default SkinIrritationFactors3;
