import { cond, allPass, lte, always, gt, T } from "ramda";

/**
 * Rankings for skin sensitivity
 * 0-4 = Non Sensitive
 * 5-12 = Slightly Sensitive
 * 13-27 = Sensitive
 * 28-100 = Highly Sensitive
 */
 export const determineDegreeOfSkinSensitivity = cond([
  [allPass([lte(28)]), always("verySensitive")],
  [allPass([lte(13), gt(28)]), always("sensitive")],
  [allPass([lte(5), gt(13)]), always("slightlySensitive")],
  [T, always("notSensitive")],
]);
