import "./Slide.css";

interface SlideProps {
  className?: string;
  children?: any;
}

function Slide(props: SlideProps) {

  return (
    <div className={`Slide ${props.className}`}>
      {props.children}
    </div>
  );
}

export default Slide;
