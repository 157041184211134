import Logo, { LogoSize } from '../../Logo/Logo';
import './Header.css';

interface HeaderProps {
  size?: string;
}

function Header(props: HeaderProps) {

  return (
    <header className="Header">
      <Logo size={LogoSize.Large} />
    </header>
  );
}

export default Header;
