import { ChartOptions, ScriptableScalePointLabelContext } from "chart.js";
import { compose, nth } from "ramda";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { top1color, top2color, top3color } from "../Complaint/Complaint";
import "./Radar.css";
import { sortTuples, top3 as getTop3 } from "./utils";

interface RadarProps {
  complaints: any[];
  expanded: boolean;
  className?: string;
  canvasRef: any;
}
const transparent = "rgba(1, 1, 1, 0.001)";
const gridColor = "rgba(28, 73, 131, 1)";
const gridLineWidth = 1.5;
const complaintsOrder = [
  "skinIrritation",
  "tingling",
  "burning",
  "sensationsOfHeat",
  "tautness",
  "itching",
  "pain",
  "generalDiscomfort",
  "hotFlashes",
  "redness",
];

function Radar(props: RadarProps) {
  const { expanded, complaints, canvasRef } = props;
  const [ordered, setOrdered] = useState<any[][]>([]);

  useEffect(() => {
    setOrdered(
      complaintsOrder.map((co) => [
        complaints.findIndex((c) => c[0] === co) + 1,
        ...complaints.find((c) => c[0] === co),
      ])
    );
  }, [complaints]);

  const data = {
    labels: ordered.map(nth(0)),
    datasets: [{ data: ordered.map(nth(2)) }],
  };
  const top3: any[] = compose(getTop3, sortTuples)(ordered);

  // TODO cleanup chart's options object
  const options: ChartOptions = {
    // TODO: play with animations/transitions to make chart's resize transition nicer
    animation: false,
    layout: { padding: 7 },
    elements: {
      line: {
        backgroundColor: "rgba(194, 240, 46, 0.7)",
        borderWidth: 0,
      },
      point: { radius: 0 },
    },
    // showLine: false,

    scales: {
      r: {
        min: 0,
        max: 10,
        grid: { color: gridColor, z: 2, lineWidth: gridLineWidth },
        pointLabels: {
          font: {
            size: expanded ? 22 : 0,
            lineHeight: 0.6,
            family: "acumin-pro",
          },
          color: (ctx: ScriptableScalePointLabelContext) => {
            if (!expanded) {
              return transparent;
            }
            switch (ctx.label) {
              case top3[0][0]:
                return top1color;
              case top3[1][0]:
                return top2color;
              case top3[2][0]:
                return top3color;
              default:
                return "#004987";
            }
          },
          borderRadius: expanded ? 12 : 6,
          backdropPadding: expanded ? 6 : 6,
          backdropColor: (ctx: ScriptableScalePointLabelContext) => {
            if (expanded) {
              return transparent;
            }
            switch (ctx.label) {
              case top3[0][0]:
                return top1color;
              case top3[1][0]:
                return top2color;
              case top3[2][0]:
                return top3color;
              default:
                return transparent;
            }
          },
        },
        ticks: { count: 4, color: transparent, backdropColor: transparent },
        angleLines: {
          color: gridColor,
          lineWidth: gridLineWidth,
          borderDash: [10, 10],
        },
      },
    },
    plugins: { legend: { display: false } },
  };

  return (
    <div className="Radar">
      <Chart
        id="radar"
        type="radar"
        ref={canvasRef}
        data={data}
        options={options}
      />
    </div>
  );
}

export default Radar;
