import { append, compose, filter, join } from "ramda";
import { isPresent } from "../../helpers";
import "./Cross.css";

const Cross = (props: {
  className?: string;
  close?: boolean;
  plus?: boolean;
  onClick?: () => void;
}) => {
  let classArr = ["Cross", props.className];
  if (props.close) {
    classArr = append("close", classArr);
  }
  if (props.plus) {
    classArr = append("plus", classArr);
  }
  const spaceJoin = join(" ");
  const filterPresent = filter(isPresent);
  const filterAndJoin = compose<any[], any[], string>(spaceJoin, filterPresent);
  const classes = filterAndJoin(classArr);

  return <span className={classes}>&#215;</span>;
};

export default Cross;
