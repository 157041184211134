import { compose, lensProp, set, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  allAreTrue,
  defaultToNegative,
  deRunt,
  withClass,
} from "../../../helpers";
import { IShowInfoContent } from "../../Info/Info";
import { IUpdateFormData } from "../../Survey/Survey";
import Legend from "../Legend/Legend";
import Slide from "../Slide/Slide";
import Slider from "../Slider/Slider";
import "./SkinIrritationFactors1.css";

// TODO: extract common part of SkinIrritationFactors1/2/3
// and VisibleSkinCondition to separate component

export interface ISkinIrritationFactors1 {
  scored_tingling?: number;
  scored_burning?: number;
  scored_sensationsOfHeat?: number;
}
type touchedSliders = {
  tingling: boolean;
  burning: boolean;
  sensationsOfHeat: boolean;
};
const tinglingTouched = lensProp<touchedSliders>("tingling");
const burningTouched = lensProp<touchedSliders>("burning");
const sensationsOfHeatTouched = lensProp<touchedSliders>("sensationsOfHeat");

interface SkinIrritationFactors1Props
  extends IUpdateFormData,
    IShowInfoContent {
  initialValues?: ISkinIrritationFactors1;
  className?: string;
  setValid: (r: boolean) => void;
}

function SkinIrritationFactors1({
  setValid,
  showInfo,
  updateData: _updateData,
  className,
  initialValues,
}: SkinIrritationFactors1Props) {
  const { t } = useTranslation();

  const [tinglingValue, setTinglingValue] = useState<number>(
    defaultToNegative(initialValues?.scored_tingling)
  );
  const [burningValue, setBurningValue] = useState<number>(
    defaultToNegative(initialValues?.scored_burning)
  );
  const [sensationsOfHeatValue, setSensationsOfHeatValue] = useState<number>(
    defaultToNegative(initialValues?.scored_sensationsOfHeat)
  );
  const [touched, setTouched] = useState<touchedSliders>({
    tingling: tinglingValue >= 0,
    burning: burningValue >= 0,
    sensationsOfHeat: sensationsOfHeatValue >= 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(compose(setValid, allAreTrue, values), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    updateData({
      scored_tingling: tinglingValue,
      scored_burning: burningValue,
      scored_sensationsOfHeat: sensationsOfHeatValue,
    });
  }, [tinglingValue, burningValue, sensationsOfHeatValue, updateData]);
  useEffect(() => {
    validate(touched);
  }, [touched, validate]);

  const handleTinglingChange = (value: number) => {
    setTinglingValue(value);
    setTouched(set(tinglingTouched, true));
  };
  const handleBurningChange = (value: number) => {
    setBurningValue(value);
    setTouched(set(burningTouched, true));
  };
  const handleSensationOfHeatChange = (value: number) => {
    setSensationsOfHeatValue(value);
    setTouched(set(sensationsOfHeatTouched, true));
  };

  return (
    <Slide className={`SkinIrritationFactors1${withClass(className)}`}>
      <p>{deRunt(t("irritationFactors1.description"))}</p>
      <Legend content={t("irritationFactors1.legend")} />
      {[
        {
          value: tinglingValue,
          label: t("irritationFactors1.tingling"),
          handleChange: handleTinglingChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors1.info1.title"),
              paragraph: t("irritationFactors1.info1.content"),
            }),
        },
        {
          value: burningValue,
          label: t("irritationFactors1.burning"),
          handleChange: handleBurningChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors1.info2.title"),
              paragraph: t("irritationFactors1.info2.content"),
            }),
        },
        {
          value: sensationsOfHeatValue,
          label: t("irritationFactors1.sensationsOfHeat"),
          handleChange: handleSensationOfHeatChange,
          handleShowInfo: () =>
            showInfo({
              title: t("irritationFactors1.info3.title"),
              paragraph: t("irritationFactors1.info3.content"),
            }),
        },
      ].map(({ value, label, handleChange, handleShowInfo }) => (
        <Slider
          key={label}
          value={value}
          label={label}
          handleChange={handleChange}
          handleShowInfo={handleShowInfo}
        />
      ))}
    </Slide>
  );
}

export default SkinIrritationFactors1;
