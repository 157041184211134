import { withClass, withClassWhen } from "../../helpers";
import Complaint from "../Complaint/Complaint";
import Cross from "../Cross/Cross";
import WhiteBox from "../WhiteBox/WhiteBox";
import "./Complaints.css";

interface ComplaintsProps {
  children?: any;
  className?: string;
  complaints: any[][];
  expanded: boolean;
  toggleExpanded: any;
}

function Complaints(props: ComplaintsProps) {
  const { className, complaints, children, toggleExpanded, expanded } = props;

  return (
    <WhiteBox
      className={`Complaints${withClass(className)}${withClassWhen(
        "expanded",
        expanded
      )}`}
    >
      <div className="rating">
        {complaints.map(([name, __], index) => (
          <Complaint
            key={name}
            index={index}
            name={String(name)}
            expanded={expanded}
          />
        ))}
      </div>
      {children}
      <div className="switcher" onClick={toggleExpanded}>
        <Cross plus={!expanded} close={expanded} />
      </div>
    </WhiteBox>
  );
}

export default Complaints;
