import { compose, lensProp, set, values } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  allAreTrue,
  defaultToNegative,
  deRunt,
  withClass,
} from "../../../helpers";
import { IShowInfoContent } from "../../Info/Info";
import { IUpdateFormData } from "../../Survey/Survey";
import Legend from "../Legend/Legend";
import Slide from "../Slide/Slide";
import Slider from "../Slider/Slider";
import "./VisibleSkinCondition.css";

export interface IVisibleSkinCondition {
  scored_redness?: number;
}
type touchedSliders = {
  redness: boolean;
};
const rednessTouched = lensProp<touchedSliders>("redness");

interface VisibleSkinConditionProps extends IUpdateFormData, IShowInfoContent {
  initialValues?: IVisibleSkinCondition;
  className?: string;
  setValid: (r: boolean) => void;
}

function VisibleSkinCondition({
  setValid,
  showInfo,
  updateData: _updateData,
  className,
  initialValues,
}: VisibleSkinConditionProps) {
  const { t } = useTranslation();
  const [rednessValue, setRednessValue] = useState<number>(
    defaultToNegative(initialValues?.scored_redness)
  );
  const [touched, setTouched] = useState<touchedSliders>({
    redness: rednessValue >= 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(compose(setValid, allAreTrue, values), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(_updateData, []);

  useEffect(() => {
    updateData({
      scored_redness: rednessValue,
    });
  }, [rednessValue, updateData]);
  useEffect(() => {
    validate(touched);
  }, [touched, validate]);

  const handleRednessChange = (value: number) => {
    setRednessValue(value);
    setTouched(set(rednessTouched, true));
  };

  return (
    <Slide className={`VisibleSkinCondition${withClass(className)}`}>
      <p>{deRunt(t("visibleSkinCondition.description"))}</p>
      <Legend content={t("visibleSkinCondition.legend")} />
      {[
        {
          value: rednessValue,
          label: t("visibleSkinCondition.redness"),
          handleChange: handleRednessChange,
          handleShowInfo: () =>
            showInfo({
              title: t("visibleSkinCondition.info1.title"),
              paragraph: t("visibleSkinCondition.info1.content"),
            }),
        },
      ].map(({ value, label, handleChange, handleShowInfo }) => (
        <Slider
          key={label}
          value={value}
          label={label}
          handleChange={handleChange}
          handleShowInfo={handleShowInfo}
        />
      ))}
    </Slide>
  );
}

export default VisibleSkinCondition;
