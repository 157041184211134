/**
 * https://usehooks-ts.com/react-hook/use-hover
 */
import { RefObject, useState } from 'react'

// See: https://usehooks-ts.com/react-hook/use-event-listener
import useEventListener from './useEventListener'

function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
): boolean {
  const [value, setValue] = useState<boolean>(false)

  const handleMouseEnter = () => setValue(true)
  const handleMouseLeave = () => setValue(false)

  // mouse events
  useEventListener('mouseenter', handleMouseEnter, elementRef)
  useEventListener('mouseleave', handleMouseLeave, elementRef)
  // touch events
  useEventListener('touchstart', handleMouseEnter, elementRef)
  useEventListener('touchend', handleMouseLeave, elementRef)

  return value
}

export default useHover